html {
    background: #fafbfb;
}

#nprogress .bar {
    z-index: 9999 !important;
    height: 3px !important;
}

.crm-Login {
    background-position-x: -200px;
    background-size: auto;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.crm-Login__box {
    color: #3e5569;
    outline: none;
    background: #fff;
    padding: 20px;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    max-width: 500px;
    min-width: 300px;
    width: 90%;
    top: 0;
    right: 0;
    height: 100%;
    margin: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.crm-Login__box_forgetLink {
    width: 150px !important;
}

@media (min-width: 767px) {
    .crm-Login {
        background-size: cover;
    }
}

b {
    font-weight: 500 !important;
}


/*MarkerCluster.Default.css*/

.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
    line-height: 30px;
}
